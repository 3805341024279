import type { ExperimentConfig } from '../@types'

export default [
	{
		main: {
			funnel: {
				id: 'ob-main-spreadsheet-creatives',
				locale: ['en'],
			},
		},
		female: {
			funnel: {
				id: 'ob-female-spreadsheet-creatives',
				locale: ['en'],
			},
		},
		male: {
			funnel: {
				id: 'ob-male-funnel',
				locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
			},
		},
	},
	{
		main: {
			funnel: {
				id: 'ob-main-spreadsheet-creatives',
				locale: ['en'],
			},
		},
		female: {
			funnel: {
				id: 'ob-female-spreadsheet-creatives',
				locale: ['en'],
			},
		},
		male: {
			funnel: {
				id: 'ob-male-funnel',
				locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
			},
		},
	},
] as ExperimentConfig[]
