import type { ExperimentConfig } from '../@types'

export default [
	{
		'activity-female-pilates': {
			paywall: {
				experiment: () =>
					import(/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_grand_upsell_activity'),
			},
		},
		'activity-female-walking': {
			paywall: {
				experiment: () =>
					import(/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_grand_upsell_activity'),
			},
		},
		'activity-male-yoga': {
			paywall: {
				experiment: () =>
					import(/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_grand_upsell_activity'),
			},
		},
	},
] as ExperimentConfig[]
