import config from '@/config'
import store from '@/store'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'

class Auth0 {
	constructor(options) {
		this._webAuth = null

		this._initPromise = new Promise((resolve) => {
			this._isInitedResolve = resolve
		})
		this._isInited = false

		this._initOptions = options
	}

	async #init(options) {
		const { default: auth0 } = await import(/* webpackChunkName: "auth0" */ 'auth0-js')

		this._webAuth = new auth0.WebAuth({
			responseType: 'token id_token',
			scope: 'openid profile email offline_access',
			redirectUri: window.location.origin + '/signin-auth0',
			...options,
		})

		this._isInitedResolve()
		this._isInited = true
	}

	#clearAuth0PersistedJWT() {
		store.commit('setAuth0Credentials', {
			accessToken: null,
			idToken: null,
			expiresAt: null,
		})
	}

	#clearOldPersistedJWT() {
		store.commit('setAuthCredentials', {
			token: null,
			refreshToken: null,
			expireAt: null,
		})
	}

	#setAuth0PersistedJWT(authResult) {
		const expiresAt = new Date(authResult.expiresIn * 1000 + new Date().getTime()).toISOString()
		store.commit('setAuth0Credentials', {
			accessToken: authResult.accessToken,
			idToken: authResult.idToken,
			expiresAt: expiresAt,
		})
	}

	#getAuth0PersistedJWT() {
		return {
			accessToken: store.getters.getAuth0Credentials.accessToken,
			idToken: store.getters.getAuth0Credentials.idToken,
			expiresAt: store.getters.getAuth0Credentials.expiresAt,
		}
	}

	async getInstance() {
		if (this._isInited) {
			return this._webAuth
		} else {
			await this.#init(this._initOptions)
			await this._initPromise
			return this._webAuth
		}
	}

	isAuthenticated() {
		const { expiresAt, accessToken, idToken } = this.#getAuth0PersistedJWT()
		if (!accessToken || !idToken || !expiresAt) {
			return false
		} else {
			return isAfter(parseISO(expiresAt), new Date())
		}
	}

	hasAuth0Token() {
		const { accessToken, idToken } = this.#getAuth0PersistedJWT()
		return Boolean(idToken || accessToken)
	}

	getUser() {
		// TODO: Implement after PoC stage
		return this.#getAuth0PersistedJWT()
	}

	async refreshSession() {
		await this.getInstance()

		return new Promise((resolve, reject) => {
			this._webAuth.checkSession(
				{
					anonymous_token: store.getters.getAuthCredentials.accessToken,
				},
				(err, authResult) => {
					if (err) {
						// Тут оказываемся, если сессия протухла. Нужно перелогиниться.
						reject(err)
					} else {
						this.#setAuth0PersistedJWT(authResult)
						resolve(this.#getAuth0PersistedJWT())
					}
				},
			)
		})
	}

	async loginWithPasswordless(email, options = {}) {
		await this.getInstance()

		return new Promise((resolve, reject) => {
			this._webAuth.passwordlessStart(
				{
					connection: 'email',
					send: 'code',
					email: email,
					...options,
				},
				(err, res) => {
					if (err) {
						reject(new Error('Auth0 passwordlessStart error', { cause: err }))
					} else {
						resolve(res)
					}
				},
			)
		})
	}

	async authorizeWithPasswordless(code, email, options = {}) {
		await this.getInstance()

		return new Promise((resolve, reject) => {
			this._webAuth.passwordlessLogin(
				{
					connection: 'email',
					verificationCode: code,
					email: email,
					anonymous_token: store.state.auth.token,
					audience: config('Auth0AudienceDomain'),
					...options,
				},
				(err, res) => {
					if (err) {
						reject(new Error('Auth0 passwordlessLogin error', { cause: err }))
					} else {
						this.#setAuth0PersistedJWT(res)
						resolve(this.#getAuth0PersistedJWT())
					}
				},
			)
		})
	}

	async checkUrlHashByAuthParams() {
		await this.getInstance()

		return new Promise((resolve, reject) => {
			this._webAuth.parseHash((err, authResult) => {
				if (authResult && authResult.accessToken && authResult.idToken) {
					this.#setAuth0PersistedJWT(authResult)
					const url = new URL(window.location.href)
					url.search = ''
					window.history.replaceState({}, document.title, url.pathname)
					this.#getAuth0PersistedJWT()
					resolve(this.#getAuth0PersistedJWT())
				} else if (err) {
					reject(new Error('Auth0 parseHash error', { cause: err }))
				}
				resolve(null)
			})
		})
	}

	// Используется для авто-логаута при протухании токена
	async logout({ redirectAfterLogoutPath = '' } = {}) {
		await this.getInstance()

		// Убиваем ВСЕ токены – auth0 потому что протух, анонимный потому что все еще имеет доступ к ресурсам как залогиненный пользователь.
		// Поведение известное, и пока ничего с ним не делаем
		this.#clearAuth0PersistedJWT()
		this.#clearOldPersistedJWT()
		return this._webAuth.logout({
			returnTo: window.location.origin + redirectAfterLogoutPath,
		})
	}

	// Логаут со "старым" поведением логаута для страницы аккаунта, но для юзеров с auth0
	async logoutAndClearStore({ redirectAfterLogoutPath = '' } = {}) {
		await this.getInstance()

		localStorage.clear()
		return this._webAuth.logout({
			returnTo: window.location.origin + redirectAfterLogoutPath,
		})
	}

	async auth0RouterGuard(to, from, next) {
		if (to.matched.some((record) => record.meta.requiresAuth0)) {
			await this.checkUrlHashByAuthParams()
			if (!this.isAuthenticated()) {
				next({ name: 'signin-auth0' })
			} else {
				next()
			}
		} else {
			next()
		}
	}
}

const auth0Instance = new Auth0({
	domain: config('Auth0Domain'),
	clientID: config('Auth0ClientId'),
	audience: config('Auth0AudienceDomain'),
})

export default auth0Instance
