import type { ExperimentConfig } from '../@types'

export default [
	{
		female: {
			paywall: {
				experiment: () =>
					import(/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_grand_upsell_fasting'),
			},
		},
		male: {
			paywall: {
				experiment: () =>
					import(/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_grand_upsell_fasting'),
			},
		},
	},
] as ExperimentConfig[]
