<template>
	<div id="app">
		<div v-if="!requiredResourcesAreReady || redirectInPending" class="loader">
			<div class="db-spinner"></div>
		</div>
		<FunnelContainer v-if="requiredResourcesAreReady">
			<ThemeProvider>
				<router-view />
				<HotjarConsentPopup
					v-if="isHotjarConsentPopupVisible"
					@submit="handleHotjarConsentApproval"
					@close="handleHotjarConsentDenial"
				/>
			</ThemeProvider>
		</FunnelContainer>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import config from '@/config'
import { getHostnameFromURL, getQueryParamsFromUrl } from '@/helpers/url'
import { filterInvalidKeys } from '@/helpers/utils'
import Jet from '@/modules/Jet'

import ExperimentsMixin from '@/mixins/Experiments'
import OnetrustMixin from '@/mixins/Onetrust'
import HotjarMixin from '@/mixins/Hotjar'

import FunnelContainer from '@/components/FunnelContainer.vue'
import ThemeProvider from '@/components/ThemeProvider.vue'
import HotjarConsentPopup from './components/HotjarConsentPopup.vue'
import { hashSha256 } from '@/helpers/hash'

export default {
	name: 'App',

	mixins: [ExperimentsMixin, OnetrustMixin, HotjarMixin],

	components: {
		FunnelContainer,
		HotjarConsentPopup,
		ThemeProvider,
	},

	data: () => ({
		routeQueryParams: {},
	}),

	computed: {
		...mapGetters({
			countryCode: 'getCountryCode',
			gdpr: 'getGdprStatus',
			requiredResourcesAreReady: 'status/requiredResourcesAreReady',
			getQueryParam: 'location/getQueryParam',
			userId: 'getUserId',
			amplitudeDisabled: 'coreFeatures/FEAT_AMPLITUDE_DISABLED',
			amplitudeWhiteList: 'coreFeatures/FEAT_AMPLITUDE_WHITE_LIST',
			amplitudeBlackList: 'coreFeatures/FEAT_AMPLITUDE_BLACK_LIST',
			paltaWhiteList: 'coreFeatures/FEAT_PALTA_WHITE_LIST',
			paltaBlackList: 'coreFeatures/FEAT_PALTA_BLACK_LIST',
			isEmptyInitialUtmParams: 'location/isEmptyInitialUtmParams',
			appAreaConfig: 'location/getAppAreaConfig',
			isOnetrustBannerAutoClosable: 'onetrust/isBannerAutoClosable',
			isOneTrustInited: 'onetrust/getIsInited',
			getResourceIsReady: 'status/getResourceIsReady',
			getPathname: 'location/getPathname',
		}),

		...mapState({
			currency: 'currency',
			marketingDeduplicationEventId: 'marketingDeduplicationEventId',
		}),

		...mapState('location', {
			initialUtmParams: 'initialUtmParams',
		}),

		redirectInPending() {
			return this.$store.getters['status/resourceIsPending']('redirect')
		},
	},

	watch: {
		networkResourceIsReady(value) {
			if (value) {
				this.initAnalyticDestinationPlugins()
				this.setAnalyticsContext()
				this.processUtmParams()
				this.$store.dispatch('location/defineOrganicTrafficFlag')
				this.setParamsToGTMDataLayer()
			}
		},
	},

	async created() {
		this.$watch(
			() => ({ isOneTrustInited: this.isOneTrustInited, isAbSmartlyReady: this.getResourceIsReady('absmartly') }),
			({ isOneTrustInited, isAbSmartlyReady }) => {
				if (
					isAbSmartlyReady &&
					isOneTrustInited &&
					this.isOnetrustBannerAutoClosable &&
					this.IS_WELCOME_COOKIE_OPTIMIZATION_EXP
				) {
					document.body.classList.add('web_welcome_cookie_optimization')
				}
			},
			{ immediate: true },
		)

		const queryParams = getQueryParamsFromUrl(window.location.href)
		this.routeQueryParams = {
			referrer: getHostnameFromURL(document.referrer),
			url: window.location.href,
			...queryParams,
		}

		this.$store.commit('location/storeQueryParams')
		const routeWithParamsSync = this.$router.resolve(`${this.getPathname()}${window.location.search}`).route

		await this.$store.dispatch('location/storeInitialPath', {
			force: Boolean(
				routeWithParamsSync.meta.isLanding &&
					(routeWithParamsSync.query.utm_source || routeWithParamsSync.redirectedFrom),
			),
			path: routeWithParamsSync.path,
		})
	},

	methods: {
		...mapMutations({
			addUserConsent: 'survey/addUserConsent',
			removeUserConsent: 'survey/removeUserConsent',
			updateLocalFlags: 'userFlags/updateLocalFlags',
		}),

		setParamsToGTMDataLayer() {
			window.dataLayer?.push({
				event_id: this.marketingDeduplicationEventId,
			})
			window.dataLayer?.push({
				event: 'updateGtagData',
				country_code_sha256: hashSha256(this.countryCode?.toUpperCase()),
			})
		},

		initAnalyticDestinationPlugins() {
			const destinations = [
				{
					instanceOptions: {
						apiKey: config('AmplitudePaltaWeb'),
						serverUrl: 'https://telemetry.dataplatform.simple.life/v2/amplitude',
					},

					whiteList: this.paltaWhiteList,
					blackList: this.paltaBlackList,
				},
			]

			if (!this.amplitudeDisabled) {
				destinations.push({
					instanceOptions: {
						apiKey: config('Amplitude'),
						serverUrl: 'https://api2.amplitude.com/2/httpapi',
					},
					whiteList: this.amplitudeWhiteList,
					blackList: this.amplitudeBlackList,
				})
			}

			this.$analytic.initDestinationPlugins(destinations)
		},

		setAnalyticsContext() {
			this.$analytic.setUserProperties({
				countryCode: this.countryCode,
				currency: this.currency,
				gdpr: this.gdpr,
				AppArea: this.appAreaConfig.appArea,
			})

			const appContext = filterInvalidKeys({
				appCurrency: this.currency,
				appGdprEnabled: this.gdpr,
			})

			const deviceContext = filterInvalidKeys({
				deviceCountry: this.countryCode,
			})

			Jet.setApplication(appContext)
			Jet.setDevice(deviceContext)
		},

		processUtmParams() {
			if (!this.routeQueryParams.utm_source) {
				// Send this event only if any page is being loaded with `utm_source` parameter in the URL
				return
			}

			const referrer = getHostnameFromURL(document.referrer)
			const commonParams = {
				referrer: referrer,
				url: window.location.href,
				fbclid: this.routeQueryParams.fbclid,
				gclid: this.routeQueryParams.gclid,
			}

			if (this.isEmptyInitialUtmParams) {
				this.$store.commit('location/storeInitialUtmParams', {
					initial_referrer: referrer,
					initial_utm_adgroup_id: this.routeQueryParams.adgroup_id,
					initial_utm_ad_id: this.routeQueryParams.ad_id,
					initial_utm_campaign_id: this.routeQueryParams.campaign_id,
					initial_utm_medium: this.routeQueryParams.utm_medium,
					initial_utm_source: this.routeQueryParams.utm_source,
					initial_utm_campaign: this.routeQueryParams.utm_campaign,
					initial_utm_content: this.routeQueryParams.utm_content,
					initial_utm_term: this.routeQueryParams.utm_term,
				})
			}

			this.$analytic.logEvent(
				'UtmParamsParsingDone',
				filterInvalidKeys({
					...commonParams,
					adgroup_id: this.routeQueryParams.adgroup_id,
					ad_id: this.routeQueryParams.ad_id,
					campaign_id: this.routeQueryParams.campaign_id,
					utm_gender: this.routeQueryParams.utm_gender,
					utm_medium: this.routeQueryParams.utm_medium,
					utm_source: this.routeQueryParams.utm_source,
					utm_campaign: this.routeQueryParams.utm_campaign,
					utm_content: this.routeQueryParams.utm_content,
					utm_term: this.routeQueryParams.utm_term,
					initial_referrer: this.initialUtmParams.initial_referrer,
					initial_utm_adgroup_id: this.initialUtmParams.initial_utm_adgroup_id,
					initial_utm_ad_id: this.initialUtmParams.initial_utm_ad_id,
					initial_utm_campaign_id: this.initialUtmParams.initial_utm_campaign_id,
					initial_utm_medium: this.initialUtmParams.initial_utm_medium,
					initial_utm_source: this.initialUtmParams.initial_utm_source,
					initial_utm_campaign: this.initialUtmParams.initial_utm_campaign,
					initial_utm_content: this.initialUtmParams.initial_utm_content,
					initial_utm_term: this.initialUtmParams.initial_utm_term,
				}),
			)

			Jet.logLogicalEvent(
				'UtmParamsParsingDone',
				filterInvalidKeys({
					...commonParams,
					adgroupId: this.routeQueryParams.adgroup_id,
					adId: this.routeQueryParams.ad_id,
					campaignId: this.routeQueryParams.campaign_id,
					utmGender: this.routeQueryParams.utm_gender,
					utmMedium: this.routeQueryParams.utm_medium,
					utmSource: this.routeQueryParams.utm_source,
					utmCampaign: this.routeQueryParams.utm_campaign,
					utmContent: this.routeQueryParams.utm_content,
					utmTerm: this.routeQueryParams.utm_term,
					initialReferrer: this.initialUtmParams.initial_referrer,
					initialUtmAdgroupId: this.initialUtmParams.initial_utm_adgroup_id,
					initialUtmAdId: this.initialUtmParams.initial_utm_ad_id,
					initialUtmCampaignId: this.initialUtmParams.initial_utm_campaign_id,
					initialUtmMedium: this.initialUtmParams.initial_utm_medium,
					initialUtmSource: this.initialUtmParams.initial_utm_source,
					initialUtmCampaign: this.initialUtmParams.initial_utm_campaign,
					initialUtmContent: this.initialUtmParams.initial_utm_content,
					initialUtmTerm: this.initialUtmParams.initial_utm_term,
				}),
			)
		},
	},
}
</script>

<style lang="scss">
@import '@/styles/style.scss';

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--background-color);
	z-index: 9;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
